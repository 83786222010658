<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              Milestone Stages
            </h3>
          </b-card-header>

          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              class="form-inline"
              label="Search"
              label-size="sm"
            >
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search reviews"
                type="text"
              />
              <b-button
                v-b-modal.applicationModal
                variant="primary"
                @click="getApplicationForm()"
              >
                <feather-icon icon="PlusIcon" />
                Add Stage
              </b-button>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'title'"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ props.formattedRow[props.column.field] }}
                  </span>
                  <b-button
                    v-if="!props.row.status || props.row.status === 'draft'"
                    variant="primary"
                    size="sm"
                    @click="$bvModal.show('applicationModal'); getApplicationForm( props.row.id, props.row.originalIndex)"
                  > Edit Stage
                  </b-button>
                  <feather-icon
                    v-if="props.row.status && props.row.status !== 'draft'"
                    v-b-tooltip="props.row.programs_assignmentquestionstables.reduce((acc, cur) => acc + cur.programs_startupresponsetables_aggregate.aggregate.count, 0) ? `Answered` : `No Response`"
                    fill="currentColor"
                    :class="props.row.programs_assignmentquestionstables.reduce((acc, cur) => acc + cur.programs_startupresponsetables_aggregate.aggregate.count, 0) ?'text-success':'text-danger'"
                    icon="CircleIcon"
                  />
                </div>
                <div class="pt-1">
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    class="mr-50"
                    :to="{name: 'review-questions',
                          params: {pid: $route.params.pid,
                                   apid:props.row.id ,
                                   aid: $route.params.aid,},
                          query: {readonly: 'true'}}"
                  >
                    <span>View Form</span>
                  </b-button>
                  <b-button
                    v-if="props.row.review_status && props.row.review_status !== 'draft'"
                    variant="outline-primary"
                    size="sm"
                    :to="{name: 'review-evaluations',params: {pid:$route.params.pid,
                                                              apid: props.row.id,
                                                              aid: $route.params.aid}}"
                  >
                    <span>View Evaluations</span>
                  </b-button></div>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  :variant="statusVariant(props.formattedRow[props.column.field])"
                  style="text-transform: capitalize;"
                >
                  {{ props.formattedRow[props.column.field] || 'Draft' }}
                </b-badge><br>
                <b-form-checkbox
                  v-b-tooltip="props.row.status==='enabled' ? 'Submissions Enabled' : 'Submissions Disabled'"
                  :checked="props.row.status==='enabled'"
                  class="mt-50 custom-control-primary"
                  name="check-button"
                  switch
                  @change="updateAssignmentStatus(props.row.status, props.row.id, `status`)"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </span>

              <!-- Column: Review Status -->
              <span v-else-if="props.column.field === 'review_status'">
                <b-badge
                  :variant="statusVariant(props.formattedRow[props.column.field])"
                  style="text-transform: capitalize;"
                >
                  {{ props.formattedRow[props.column.field] || 'Draft' }}
                </b-badge><br>
                <b-form-checkbox
                  v-b-tooltip="props.row.review_status==='enabled' ? 'Evaluations Enabled' : 'Evaluations Disabled'"
                  :checked="props.row.review_status==='enabled'"
                  class="mt-50 custom-control-primary"
                  name="check-button"
                  switch
                  @change="updateAssignmentStatus(props.row.review_status, props.row.id, `review_status`)"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </span>
              <span
                v-else-if="props.column.field === 'recommendations'"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <b-badge
                    v-if="props.row.programs_assignmentscorerecords[0].verdict"
                    size="sm"
                    class="mr-50"
                    :variant="statusVariant(props.row.programs_assignmentscorerecords[0].verdict)"
                  >{{ props.row.programs_assignmentscorerecords[0].verdict }}</b-badge>
                  <b-button
                    class="ml-auto"
                    size="sm"
                    variant="flat-primary"
                    @click="selectedRow=props.row.originalIndex; updateModal = Object.assign({},props.row.programs_assignmentscorerecords[0]); $bvModal.show('recommendationModal')"
                  >Update</b-button></div>
                <div class="font-small-4 pt-50 pl-50">{{
                  props.row.programs_assignmentscorerecords[0].recommendation
                }}</div>
              </span>
              <span
                v-else-if="props.column.label === 'Verdict'"
                class="d-flex justify-content-between align-items-center"
              >
                {{ props.formattedRow[props.column.field] || '-' }}
                <b-button
                  size="sm"
                  variant="flat-primary"
                  @click="selectedRow=props.row.originalIndex; $bvModal.show('recommendationModal')"
                >Update</b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
      <b-modal
        id="applicationModal"
        ok-only
        no-close-on-backdrop
        ok-title="Submit"
        size="lg"
        title="Milestone Stage Review Form"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Title"
              label-size="sm"
            >
              <b-form-input
                v-model="reviewTitle"
                placeholder="Review Title"
                required
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Type"
              label-size="sm"
            >
              <b-form-input
                v-model="reviewType"
                placeholder="Review Type"
                required
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <form-builder
          v-model="applicationForm"
          :inherited-sections="applicationForm"
        />
        <template v-slot:modal-footer>
          <div class="d-flex justify-content-between w-100 align-items-center">
            <b-button
              v-if="reviewId"
              size="sm"
              variant="flat-danger"
              @click="deleteReview"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-25"
              />
              Delete Stage
            </b-button>
            <b-button
              class="ml-auto"
              variant="primary"
              @click="addReview"
            >
              Submit
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-col>

    <b-modal
      id="recommendationModal"
      ok-only
      no-close-on-backdrop
      ok-title="Update"
      title="Recommendations"
      @ok="updateStatus"
    >
      <div v-if="rows[selectedRow]">
        <b-form-group
          label="Verdict"
        >
          <v-select
            v-model="updateModal.verdict"
            :options="['Excellent','Good','Satisfactory','Unsatisfactory','Incomplete']"
            placeholder="Select from list"
          />
        </b-form-group>
        <b-form-group
          label="Recommendations"
        >
          <b-form-input
            v-model="updateModal.recommendation"
            placeholder="Your recommendation for startup"
          />
        </b-form-group>

      </div>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder.vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BFormCheckbox,
    vSelect,
    FormBuilder,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Stage',
          field: 'title',
        },
        {
          label: 'Submission',
          field: 'status',
          tdClass: 'text-center',
          width: '10%',
        },
        {
          label: 'Evaluation',
          field: 'review_status',
          tdClass: 'text-center',
          width: '10%',
        },
        {
          label: 'Recommendations',
          field: 'recommendations',
          width: '30%',
        },
      ],
      rows: [],
      searchTerm: '',
      reviewId: null,
      reviewTitle: null,
      reviewType: null,
      selectedRow: null,
      applicationForm: null,
      mutationLoading: null,
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      updateModal: {
        recommendation: null,
        verdict: null,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        enabled: 'light-primary',
        disabled: 'light-danger',
        draft: 'light-secondary',
        null: 'light-secondary',
        Excellent: 'light-success',
        Good: 'light-success',
        Satisfactory: 'light-warning',
        Unsatisfactory: 'light-danger',
        Incomplete: 'light-secondary',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    addReview() {
      this.mutationLoading = true
      // Iterate over the applicationForm and remove options key if it is empty
      this.applicationForm.forEach(item => {
        if (item.options && !item.options.length) {
          // eslint-disable-next-line no-param-reassign
          delete item.options
        }
      })

      const object = {
        title: this.reviewTitle,
        type: this.reviewType,
        progress_stage_id: this.$route.params.id,
        programs_assignmentquestionstables: {
          data: this.applicationForm,
          on_conflict: {
            constraint: 'programs_assignmentquestionstable_pkey',
            update_columns: ['question', 'section', 'options', 'input_type', 'is_required'],
          },
        },
      }
      if (this.reviewId) {
        object.id = this.reviewId
        // List of question ids in DB
        const questionIds = this.rows.find(({ id }) => id === this.reviewId).programs_assignmentquestionstables.map(({ id }) => id)
        // List of question ids in this.applicationForm
        const newQuestionIds = this.applicationForm.map(({ id }) => id)
        // List of question ids to be deleted
        const deleteQuestionIds = questionIds.filter(id => !newQuestionIds.includes(id))
        // Mutation to delete questions
        if (deleteQuestionIds.length) {
          this.$apollo.mutate(
            {
              mutation: gql`mutation($ids: [Int!]!) {
                            delete_programs_assignmentquestionstable(where: {id: {_in: $ids}}) {
                              affected_rows
                            }
                          }`,
              variables: {
                ids: deleteQuestionIds,
              },
            },
          )
            .catch(() => {
              this.mutationLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to delete questions.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
      this.$apollo.mutate(
        {
          mutation: gql`mutation($object: programs_assignmenttable_insert_input!) {
                        insert_programs_assignmenttable_one(object: $object, on_conflict: {constraint: programs_assignmenttable_pkey, update_columns: [title, type]}) {
                          id
                        }
                      }`,
          variables: {
            object,
          },
          update: () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Stage added successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        },
      )
        .catch(() => {
          this.mutationLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to add stage.',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
      this.reviewTitle = null
      this.reviewType = null
      this.applicationForm = null
      // CLose modal
      this.$bvModal.hide('applicationModal')
    },
    deleteReview() {
      this.$bvModal.msgBoxConfirm("You're about to delete a stage. Are you sure?", {
        title: 'Confirm Delete',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes, Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.mutationLoading = true
            //   Apollo mutate to update is_deleted as true
            this.$apollo.mutate(
              {
                mutation: gql`mutation($id: Int!) {
                            update_programs_assignmenttable_by_pk(pk_columns: {id: $id}, _set: {is_deleted: true}) {
                              id
                            }
                          }`,
                variables: {
                  id: this.reviewId,
                },
                update: () => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Stage deleted successfully',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  this.$apollo.queries.rows.refetch()
                  this.mutationLoading = false
                },
              },
            )
              .catch(() => {
                this.mutationLoading = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Failed to delete stage.',
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    getApplicationForm(reviewId = null, assignmentId = null) {
      this.reviewId = reviewId

      if (reviewId) {
        const review = this.rows.find(({ id }) => id === reviewId)
        this.reviewTitle = review.title
        this.reviewType = review.type
      } else {
        this.reviewTitle = null
        this.reviewType = null
      }

      if (!assignmentId) {
        this.applicationForm = []
        return
      }
      this.applicationForm = this.rows[assignmentId].programs_assignmentquestionstables.map(({
        programs_startupresponsetables_aggregate,
        ...rest
      }) => rest)
    },
    updateStatus() {
      this.mutationLoading = true
      this.$apollo.mutate(
        {
          mutation: gql`mutation MyMutation($object: programs_assignmentscorerecord_insert_input!) {
              insert_programs_assignmentscorerecord_one(object: $object, on_conflict: {constraint: programs_assignmentscorerecord_pkey, update_columns: [verdict, recommendation]}) {
                id
              }
            }`,
          variables: {
            object: this.updateModal,
          },
          update: () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mutationLoading = false
            this.$apollo.queries.rows.refetch()
          },
        },
      )
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'There was an error',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateAssignmentStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_assignmenttable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_assignmenttable(where: {progress_stage_id: {_eq: ${this.$route.params.id}}})  {
            id
            title
            status
            review_status
            type
            programs_assignmentquestionstables{
                programs_startupresponsetables_aggregate(where: {participant_id: {_eq: ${this.$route.params.aid}}}) {
                    aggregate {
                        count
                    }
                }
                id
                question
                section
                options
                input_type
                is_required
            }
            programs_assignmentscorerecords (where: {participant_id: {_eq: ${this.$route.params.aid}}}) {
              id
              verdict
              recommendation
              assignment_id
              }
            }
          }`
      },
      update(data) {
        const rows = data.programs_assignmenttable
        rows.forEach(row => {
          if (!row.programs_assignmentscorerecords.length) {
            row.programs_assignmentscorerecords.push({
              assignment_id: row.id,
              participant_id: this.$route.params.aid,
              verdict: null,
              recommendation: null,
            })
          }
        })
        return rows
      },
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
